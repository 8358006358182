import React from 'react';
import { graphql } from 'gatsby';
import Homepage from '../templates/multilanguage/Homepage';

const Index = ({ data }) => {
  return <Homepage data={data} />
};

export const query = graphql`query HomepageListIndexRo {
  mdx(frontmatter: {type: {eq: "homepage"} language: {eq: "de"}}) {
      id
      fields {
        slug
        canonical
        lang
        relatedPages {
          en {
            url
            country
          }
          it {
            url
            country
          }
          ro {
            url
            country
          }
          es {
            url
            country
          }
          fr {
            url
            country
          }
          de {
            url
            country
          }
          pl {
            url
            country
          }
          cz {
            url
            country
          }
        }
      }
      exports {
        testimonials {
          title
          testimonialExcerpt
          testimonialText
          person
          company
          companyUrl
          imagePosition
          iconTitles
          iconImageClasses
          iconDescriptions
          image {
            publicURL
          }
          alt
          refId
          body
        }
      }
      frontmatter {
        metaTitle
        metaDescription
        metaImage {
          publicURL
        }
        metaCover {
          publicURL
        }
        metaKeywords
        twitterCard
        ldJson
        type
        title
        headerText
        headerSubtext
        freeTrialButtonHeader
        freeTrialButtonSubHeader
        freeTrialButtonSubTexts
        freeTrialButtonText
        requestDemoButtonText
        demoEmailPlaceholder
        demoEmailIcon
        demoNotesPlaceholder
        demoNotesIcon
        demoSubmitButtonText
        demoText
        demoHeader
        wishMainText
        wishCarouselTexts
        wishSubtext
        somethingMissingTitle
        somethingMissingDescription
        cloudSvg {
          publicURL
        }
        cloudSvgAlt
        mobileCloudSvgAlts
        andDigitalHeaderStart
        andDigitalHeaderEnd
        andDigitalHeaderMiddle
        andDigitalSubheader
        andDigitalQuoteText
        andDigitalName
        andDigitalFunction
        andDigitalLogoUrl
        diveIn
        tryItTexts
        andDigitalLogo {
          childImageSharp {
            gatsbyImageData(
              quality: 95
              tracedSVGOptions: {color: "#00ab6b"}
              placeholder: BLURRED
              transformOptions: {cropFocus: CENTER}
              layout: FULL_WIDTH
            )
          }
        }
        andDigitalLogoAlt
        andDigitalPersonPicture {
          childImageSharp {
            gatsbyImageData(
              width: 150
              height: 150
              quality: 95
              tracedSVGOptions: {color: "#00ab6b"}
              placeholder: BLURRED
              transformOptions: {cropFocus: CENTER}
              layout: CONSTRAINED
            )
          }
        }
        andDigitalPersonPictureAlt
        mobileAppTitle
        mobileAppLeftDescription
        mobileAppRightDescription
        mobileAppPicture {
          publicURL
        }
        mobileAppPictureAlt
      }
      id
  }
}
`

export default Index;
